<template>
  <div class="table borders">
    <table>
      <thead>
      <tr>
        <th width="33">
          <a @click="$emit('plus')">
            <img src="../../../../assets/images/icon/plus-circle-red.png"/>
          </a>
        </th>
        <th>{{$t('Project')}}</th> <!--項目-->
        <th width="80">{{$t('UnitPrice')}}</th> <!--單價-->
        <th width="100">{{$t('Quantity')}}</th> <!--數量-->
        <th width="80">{{$t('Amount')}}</th> <!--金額-->
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) of datas" :key="item.id">
        <td class="bg">
          <a @click="datas.splice(index,1)">
            <img src="../../../../assets/images/icon/ashcan.png"/>
          </a>
        </td>
        <td>
          <div class="input-wrap">
            <input type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="item.cancelCategoryName"/>
          </div>
        </td>
        <td>
          <div class="input-wrap">
            <el-input-number class="input-number" :min="0" :max="999999" size="small"
                             :placeholder="$t('PleaseEnter')"
                             :controls="false"
                             :precision="2"
                             v-model.trim="item.unitPrice"/>
          </div>
        </td>
        <td>
          <div class="input-wrap">
            <el-input-number class="input-number" :min="0" :max="9999" size="small"
                             :placeholder="$t('PleaseEnter')"
                             step-strictly
                             :controls="false"
                             v-model.trim="item.amount"/>
          </div>
        </td>
        <td>{{(item.unitPrice||'')*1000*(item.amount||'')/1000}}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="5">
          <!--小計-->
          <div class="txt-r b">{{$t('Subtotal')}}：{{subtotal}}</div>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
  export default {
    name: 'RefundFeesForm',
    props: {
      datas: Array
    },
    computed: {
      // 费用小计
      subtotal() {
        let total = 0;
        for (let item of this.datas) {
          total += (item.unitPrice || 0) * 1000 * (item.amount || 0) / 1000;
        }
        return total;
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../../assets/style/table";
  .table{
    .input-number{
      width:90px;
      /deep/ .el-input__inner{padding:0;border:none;}
    }
    tbody td{background-color:#fff;}
  }
</style>
