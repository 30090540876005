<template>
  <div class="table border" v-if="order">
    <table>
      <thead>
      <tr>
        <th>{{$t('Charging items')}}</th>
        <th>{{$t('Price')}}</th>
        <th>{{$t('Quantity')}}</th>
        <th>{{$t('Amount')}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{$t(order.priceType!=='1'?'AdultPrice':'Passenger')}}</td>
        <td><!--{{order.billingType}} -->{{order.adultPrice/100}}</td>
        <td>{{order.adultNumber}}</td>
        <td><!--{{order.billingType}} -->{{order.adultPrice*order.adultNumber/100}}</td>
      </tr>
      <tr v-if="order.childNumber">
        <td>{{$t('ChildPrice')}}</td>
        <td><!--{{order.billingType}} -->{{order.childPrice/100}}</td>
        <td>{{order.childNumber}}</td>
        <td><!--{{order.billingType}} -->{{order.childNumber*order.childPrice/100}}</td>
      </tr>
      <tr v-if="order.notChildNumber">
        <td>{{$t('NotChildPrice')}}</td>
        <td><!--{{order.billingType}} -->{{order.notChildPrice/100}}</td>
        <td>{{order.notChildNumber}}</td>
        <td><!--{{order.billingType}} -->{{order.notChildNumber*order.notChildPrice/100}}</td>
      </tr>
      <tr v-if="order.babyNumber">
        <td>{{$t('BabyPrice')}}</td>
        <td><!--{{order.billingType}} -->{{order.babyPrice/100}}</td>
        <td>{{order.babyNumber}}</td>
        <td><!--{{order.billingType}} -->{{order.babyNumber*order.babyPrice/100}}</td>
      </tr>
      <tr v-if="order.singleBedNumber">
        <td>{{$t('SingleBedDifference')}}</td>
        <td><!--{{order.billingType}} -->{{order.singleBedPrice/100}}</td>
        <td>{{order.singleBedNumber}}</td>
        <td><!--{{order.billingType}} -->{{order.singleBedNumber*order.singleBedPrice/100}}</td>
      </tr>
      <template v-if="order.isInsurance==='Y'">
        <tr>
          <td>{{$t('Insurance')}}{{order.priceType!=='1'?`-${$t('Adult')}`:''}}</td>
          <td><!--{{order.billingType}} -->{{order.insuranceAdultPrice/100}}</td>
          <td>{{order.adultNumber}}</td>
          <td><!--{{order.billingType}} -->{{order.insuranceAdultTotalPrice}}</td>
        </tr>
        <tr v-if="order.priceType!=='1'&&(order.childNumber+order.notChildNumber+order.babyNumber)">
          <td>{{$t('Insurance')}}-{{$t('Child')}}/{{$t('Baby')}}</td>
          <td><!--{{order.billingType}} -->{{order.insuranceChildPrice/100}}</td>
          <td>{{order.childNumber+order.notChildNumber+order.babyNumber}}</td>
          <td><!--{{order.billingType}} -->{{order.insuranceChildTotalPrice}}</td>
        </tr>
      </template>
      </tbody>
      <tfoot>
      <tr class="b">
        <td>{{$t('Total')}}</td>
        <td class="txt-r" colspan="3">{{order.billingType}} {{order.totalMoney||order.totalPrice}}</td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
  export default {
    name: 'ChargeDetailsTable',
    props: {
      order: Object
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
</style>
