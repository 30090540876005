<template>
  <!--退款詳情及新訂單-->
  <Modal :title="$t(orderInfo?'RefundDetailsAndNewOrders':'Refund details')" width="960px"
         :is-show="isShow"
         v-if="isShow"
         @close-modal="hide">
    <Tabs class="submit-modal" v-model="activeName">
      <!--退款詳情-->
      <TabPane :label="$t('Refund details')" name="ByWeek">
        <div class="refund-details">
          <refund-details :order="order" :details="orderCancel"/>
          <div class="h3">
            <div class="cl">
              <!--退款明細-->
              <div class="fl">{{$t('RefundDetails')}}</div>
              <!--交易貨幣-->
              <div class="fr currency">{{$t('TradingCurrency')}}：{{$t('Currencys')[orderCancel.billingType]}}</div>
            </div>
          </div>
          <refund-table :details="orderCancelDetails"/>
        </div>
      </TabPane>
      <!--新訂單-->
      <TabPane :label="$t('NewOrder')" name="ByDate" v-if="orderInfo">
        <new-order :order-info="orderInfo"
                   :passenger-info="travelers"
                   :contact-information="contactInformation"
                   :emergency-contact="emergencyContact"
                   :country-options="countryOptions"
                   :certificate-type-options="certificateTypeOptions"
                   :appellation-options="appellationOptions"
                   :isDay="isDay"
                   :plus-cost="plusCost"/>
      </TabPane>
    </Tabs>
    <div class="actions">
      <slot>
        <!--確認並發出-->
        <button class="button button-primary" @click="onSubmit">{{$t('ConfirmAndSend')}}</button>
      </slot>
    </div>
  </Modal>
</template>
<script>
  import RefundDetails from '../../../../components/RefundDetails';
  import RefundTable from '../../../../components/RefundTable';
  import NewOrder from './NewOrder';
  import order from '../../../../mixins/order';
  import {getRefundDetails, getNewOrderDetails, sendCancelOrder, getOrder} from '../../../../api/admin-order';
  import {getDictList} from '../../../../api/admin-common';

  export default {
    name: 'SubmitModal',
    mixins: [order],
    props: {
      isDay: Number
    },
    data() {
      return {
        isShow: false,
        activeName: 'ByWeek',
        orderId: '',
        orderCancel: {},
        orderCancelDetails: [],
        order: null,
        orderInfo: null,
        travelers: [],
        contactInformation: {},
        emergencyContact: {},
        countryOptions: [],
        certificateTypeOptions: [],
        appellationOptions: [],
        plusCost: []
      };
    },
    components: {RefundTable, RefundDetails, NewOrder},
    created() {
      this.orderId = this.$route.query.id;
      this.getDicts();
    },
    methods: {
      show() {
        this.getRefunddetails();
        this.getNewOrderDetails();
        this.getOrder();
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      // 获取字典
      getDicts() {
        // 国家/证件签发地
        getDictList('country').then(res => {
          this.countryOptions = res.value || [];
        });

        // 证件类型
        getDictList('certificateType').then(res => {
          this.certificateTypeOptions = res.value || [];
        });

        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });
      },
      // 獲取原訂單基本信息
      getOrder() {
        getOrder(this.orderId).then(res => {
          console.log('order', res.value);
          this.order = res.value || {};
        });
      },
      // 退款詳情
      getRefunddetails() {
        getRefundDetails(this.orderId).then(res => {
          const data = res.value || {};
          this.orderCancel = data.orderCancel || {};
          this.orderCancelDetails = data.orderCancelDetails || [];
        });
      },
      // 获取新订单详情
      getNewOrderDetails() {
        getNewOrderDetails(this.orderId).then(res => {
          const data = res.value;
          if (data) {
            this.orderInfo = data.newOrder.order;
            this.travelers = data.newOrder.travelers;
            this.contactInformation = this.createContactInformation(data.newOrder.contacts);
            this.emergencyContact = this.createContactInformation(data.newOrder.contacts, 'urgent');
            this.plusCost = data.plusCost;
          }
        });
      },
      // 確認並發出
      onSubmit() {
        const loading = this.$loading({text: 'Loading'});
        sendCancelOrder(this.orderId).then(res => {
          this.$message.success('發出成功！');
          this.$router.push({name: 'AdminOrderDetail', query: {id: this.orderId}});
          loading.close();
        }).catch(() => {
          this.$message.error('發出失敗！');
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../order/refundDetails";
  .submit-modal{
    padding:0 30px;
    .refund-details{padding-bottom:0;}
    .currency{margin-top:20px;line-height:1;font-size:14px;font-weight:normal;}
    /deep/ .tab-nav{margin-top:16px;text-align:left;}
  }
  .actions{padding:40px;}
</style>
