import request from '@/utils/request';

// 获取我的订单列表
export function getOrderList(params, data) {
  return request({
    url: '/admin/order/list',
    baseURL: '/v2',
    method: 'post',
    params,
    data
  });
}

// 获取订单基本信息
export function getOrder(orderId) {
  return request({
    url: `/admin/order/getOrder/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取订单详情
export function getOrderDetail(orderId) {
  return request({
    url: `/admin/order/all/details/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取团号详情
/*
export function getOrderGroupNumberDetail(groupNumber) {
  return request({
    url: `/admin/order/groupNumber/details/${groupNumber}`,
    baseURL: '/v2',
    method: 'get'
  });
}
*/
export function getOrderGroupNumberDetail(params, data) {
  return request({
    url: '/admin/order/groupNumber/',
    baseURL: '/v2',
    method: 'post',
    params,
    data
  });
}
// 获取团号基本信息
export function getOrderGroupNumberInfo(data) {
  return request({
    url: '/admin/order/groupNumberInfo',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 保存旅客资料
export function saveOrderTravelerInfo(orderId, data) {
  return request({
    url: `/admin/order/save/traveler/${orderId}`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 保存联系人资料
export function saveOrderContactInfo(orderId, data) {
  return request({
    url: `/admin/order/save/contact/${orderId}`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 修改订单状态
export function updateOrderStatus(data) {
  return request({
    url: '/admin/order/sure/refund',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 商家发起退款查询订单信息
export function getCancelOrderInfo(orderId) {
  return request({
    url: `/admin/cancel/order/already/order/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 商家发起退款
export function cancelOrderBusiness(data) {
  return request({
    url: '/admin/cancel/order/business',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取取消订单的详情
export function getCancelOrderDetails(orderId) {
  return request({
    url: `/admin/cancel/order/details/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取取消订单的修改信息(提交前的临时数据)
export function getCancelOrderUpdateInfo(orderId) {
  return request({
    url: `/admin/cancel/order/update/info/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取退款详情
export function getRefundDetails(orderId) {
  return request({
    url: `/admin/cancel/order/cancel/details/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取新订单详情
export function getNewOrderDetails(orderId) {
  return request({
    url: `/admin/cancel/order/new/order/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 发送取消订单给用户
export function sendCancelOrder(orderId) {
  return request({
    url: `/admin/cancel/order/send/user/${orderId}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 取消订单列表
export function getCancelOrderList(params, data) {
  return request({
    url: '/admin/cancel/order/list',
    baseURL: '/v2',
    method: 'post',
    params,
    data
  });
}

// 调团申请
export function orderMission(data) {
  return request({
    url: '/admin/order/mission',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 新建人工订单
export function addManualOrder(data) {
  return request({
    url: '/admin/order/artificial/booking',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 保存人工订单的旅客等信息
export function saveManualOrderInfo(orderId, data) {
  return request({
    url: `/admin/order/save/memo/${orderId}`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 保存人工订单的旅客等信息
export function saveOrderMaterial(orderId, data) {
  return request({
    url: `/admin/order/save/material/${orderId}`,
    method: 'post',
    baseURL: '/v2',
    data
  });
}

// 现金支付
export function cashPayment(orderId) {
  return request({
    url: `/admin/order/artificial/update/order/status/${orderId}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 报名成功后【确认订单】操作
export function addSignUpSuccessForOrderByOrderId(orderId, data) {
  return request({
    url: `/admin/order/addSignUpSuccessForOrderByOrderId/${orderId}`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 发出成团通知书
export function addGroupSuccessForOrderByOrderId(orderId, data) {
  return request({
    url: `/admin/order/addGroupSuccessForOrderByOrderId/${orderId}`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取订单收据
export function getOrderReceipt(orderId) {
  return request({
    url: `/admin/order/getOrderReceipt/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 获取订单成团通知
export function getOrderNotice(orderId) {
  return request({
    url: `/admin/order/getOrderNotice/${orderId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// Admin send message to client
export function sendMessageToClient(data, orderID){
  return request({
    url: `/admin/message/messageToClient/${orderID}`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取产品基本信息
export function getProduct(productId) {
  return request({
    url: `/product/getProduct/${productId}`,
    method: 'get'
  });
}

// 获取产品基本信息
export function adminGetProduct(productId) {
  return request({
    url: `admin/product/getProduct/${productId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 导出团号excel
export function exportExcel(data) {
  return request({
    url: '/admin/order/exportExcel',
    baseURL: '/v2',
    method: 'post',
    data
  });
}
