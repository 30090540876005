<template>
  <div class="main">
    <div class="actions cl">
      <div class="fl">
        <div class="title">{{$t('Cancel order')}}</div>
      </div>
      <div class="fr">
        <button class="button small" @click="$router.go(-1)">返回</button>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd">
        <el-popover popper-class="popover-refund-policy-wrap" placement="bottom-start" width="800" trigger="hover">
          <div class="policy-box">
            <div class="rich-text" v-html="refundPolicyContent"></div>
            <cancellation-policy-table :policy="policies"/>
          </div>
          <span slot="reference">
            {{$t('CancellationPolicy')}} <i class="el-icon-arrow-down"></i>
          </span>
        </el-popover>
      </div>
    </div>
    <!-- 第一步 -->
    <div class="container" v-if="step===0">
      <div class="main-left">
        <!--原訂單-->
        <div class="hd">{{$t('OriginalOrder')}}</div>
        <div class="fl-group">
          <!--訂單概况-->
          <div class="hd">{{$t('OrderOverview')}}</div>
          <div class="order-info">
            <!--訂單編號-->
            <div class="p">{{$t('Order number')}}：{{order.orderNo}}</div>
            <!--產品編號-->
            <div class="p">{{$t('ProductNumber')}}：{{order.productNo}}</div>
            <!--產品名稱-->
            <div class="p">{{$t('ProductName')}}：{{order.productTitle}}</div>
            <!--路線-->
            <div class="p">{{$t('Route')}}：{{order.lineName}}</div>
            <!--出發日期-->
            <div class="p">{{$t('DepartureDate')}}：{{order.priceDate}}</div>
            <!--房間數目-->
            <div class="p" v-if="isDay===0">{{$t('NumberOfRooms')}}：{{order.roomsNumber}}</div>
          </div>
        </div>
        <div class="fl-group">
          <div class="hd">{{$t('Traveler information')}}</div>
          <div class="table border">
            <table>
              <thead>
              <tr>
                <th>{{$t('English name')}}</th> <!--英文名-->
                <th>{{$t('Certificate Number')}}</th> <!--證件號碼-->
                <th>{{$t('TravellerType')}}</th> <!--旅客類型-->
                <th>{{$t('CancelApplication')}}</th><!--取消報名-->
              </tr>
              </thead>
              <tbody>
              <tr v-for="item of travelers" :key="item.id">
                <td>{{item.englishSurname}}/{{item.englishName}}</td>
                <td>{{item.idNumber}}</td>
                <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
                <td>
                  <checkbox v-model="item.isSelected"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="fl-group">
          <div class="hd cl">
            <!--交易貨幣-->
            <div class="fr currency">{{$t('TradingCurrency')}}：
              <span class="b">{{this.$t('Currencys')[order.billingType]}}</span>
            </div>
            {{$t('Cost Details')}}<!--費用明細-->
          </div>
          <charge-details-table :order="order"/>
        </div>
      </div>
      <div class="main-right">
        <!--取消及退款-->
        <div class="hd">{{$t('CancellationAndRefund')}}</div>
        <div class="fl-group">
          <!--取消原因-->
          <div class="hd">{{$t('CancellationReason')}}</div>
          <div class="bd">
            <div>
              <el-select class="select" v-model="cancelReason" size="small" :placeholder="$t('CancellationReason')">
                <el-option v-for="item in cancelReasonOptions"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
              <el-input class="m-t"
                        type="textarea"
                        :placeholder="$t('Other reasons')"
                        v-model="otherReasons"
                        maxlength="1000"
                        v-if="cancelReason==='cancelOhter'">
              </el-input>
            </div>
            <div class="m-t" v-if="selectedTravelers.length">
              <div class="table border">
                <table>
                  <thead>
                  <tr>
                    <th><a class="is-link" @click="uncheckAllTraveler">{{$t('Clear form')}}</a></th> <!--清空-->
                    <th>{{$t('English name')}}</th> <!--英文名-->
                    <th>{{$t('TravellerType')}}</th> <!--旅客類型-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item of selectedTravelers" :key="item.id">
                    <td>
                      <a @click="uncheckTraveler(item.id)">
                        <img src="../../../assets/images/icon/ashcan.png"/>
                      </a>
                    </td>
                    <td>{{item.englishSurname}}/{{item.englishName}}</td>
                    <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="fl-group" v-if="selectedTravelers.length">
          <div class="hd cl">
            <!--交易貨幣-->
            <div class="fr currency">{{$t('TradingCurrency')}}：
              <span class="b">{{this.$t('Currencys')[order.billingType]}}</span>
            </div>
            {{$t('OtherFee')}} <!--其他費用-->
          </div>
          <div class="table border">
            <table>
              <thead>
              <tr>
                <th>{{$t('Charging items')}}</th> <!--收費項目-->
                <th>{{$t('Quantity')}}</th> <!--數目-->
              </tr>
              </thead>
              <tbody>
              <tr v-if="order.singleBedNumber">
                <td>{{$t('SingleBedDifference')}}</td>
                <td>{{order.singleBedNumber}}</td>
              </tr>
              <template v-if="order.isInsurance==='Y'&&travelers.length===selectedTravelers.length">
                <tr>
                  <td>{{$t('Insurance')}}{{order.priceType!=='1'?`-${$t('Adult')}`:''}}</td>
                  <td>{{order.billingType}} {{order.insuranceAdultPrice/100}}</td>
                  <td>{{order.adultNumber}}</td>
                  <td>{{order.billingType}} {{order.insuranceAdultTotalPrice}}</td>
                </tr>
                <tr v-if="order.priceType!=='1'&&(order.childNumber+order.notChildNumber+order.babyNumber)">
                  <td>{{$t('Insurance')}}-{{$t('Child')}}/{{$t('Baby')}}</td>
                  <td>{{order.billingType}} {{order.insuranceChildPrice/100}}</td>
                  <td>{{order.childNumber+order.notChildNumber+order.babyNumber}}</td>
                  <td>{{order.billingType}} {{order.insuranceChildTotalPrice}}</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="fl-group" v-if="selectedTravelers.length">
          <!--補償-->
          <div class="hd">{{$t('Reimburse')}}</div>
          <refund-fees-form :datas="plusDetails" @plus="plusDetails.push(addRefundItem('cancelOrder', 'plus'))"/>
        </div>
        <div class="fl-group" v-if="selectedTravelers.length">
          <!--扣減-->
          <div class="hd">{{$t('Deduction fee')}}</div>
          <refund-fees-form :datas="deductionDetails"
                            @plus="deductionDetails.push(addRefundItem('cancelOrder', 'deduction'))"/>
        </div>
        <!--新訂單-->
        <template v-if="selectedTravelers.length&&travelers.length>selectedTravelers.length">
          <div class="hd m-t">{{$t('NewOrder')}}</div>
          <div class="fl-group">
            <div class="hd cl">
              <div class="fr" v-if="isDay===0">
                <!--房間數目-->
                <div class="select-label">{{$t('NumberOfRooms')}}：</div>
                <el-select class="select w1" size="small" v-model="roomsNumber">
                  <el-option v-for="item of roomsOptions"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
              {{$t('Traveler information')}} <!--旅客資料-->
            </div>
            <div class="table border">
              <table>
                <thead>
                <tr>
                  <th>{{$t('English name')}}</th> <!--旅客資料-->
                  <th>{{$t('TravellerType')}}</th> <!--旅客類型-->
                  <th>{{$t('Gender')}}</th> <!--性别-->
                  <th v-if="isDay===0">{{$t('SharedRoom')}}</th> <!--拼房-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) of uncheckedTravelers" :key="item.id">
                  <td>{{item.englishSurname}}/{{item.englishName}}</td>
                  <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
                  <td>{{item.genderType|dictName('gender')}}</td>
                  <td v-if="isDay===0">
                    <checkbox :value="occupyBedIndex===index"
                              @click.native="occupyBedIndex=index"
                              v-if="item.travelerType==='adult'&&roomsNumber%1===0.5"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="fl-group" v-if="roomNumberDetail.annexNumber">
            <div class="hd cl">
              <!--交易貨幣-->
              <div class="fr currency">{{$t('TradingCurrency')}}：
                <span class="b">{{this.$t('Currencys')[order.billingType]}}</span>
              </div>
              {{$t('OtherFee')}} <!--其他費用-->
            </div>
            <div class="table border">
              <table>
                <thead>
                <tr>
                  <th>{{$t('Charging items')}}</th>
                  <th>{{$t('Quantity')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{$t('SingleBedDifference')}}</td>
                  <td>{{roomNumberDetail.annexNumber}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="fl-group">
            <!--其他費用-->
            <div class="hd">{{$t('OtherFee')}}</div>
            <refund-fees-form :datas="newDetails" @plus="newDetails.push(addRefundItem('newOrder', 'deduction'))"/>
          </div>
        </template>
        <div class="btns">
          <button class="button button-primary" :disabled="!selectedTravelers.length||!cancelReason"
                  @click="cancelOrderBusiness">
            {{$t('NextStep')}}
          </button>
        </div>
      </div>
    </div>
    <!-- 第二步 -->
    <div v-else-if="step===1">
      <div class="goods-info">
        <!--產品編號-->
        <div class="item">{{$t('ProductNumber')}}：{{order.productNo}}</div>
        <!--產品名稱-->
        <div class="item">{{$t('ProductName')}}：{{order.productTitle}}</div>
        <!--路線-->
        <div class="item">{{$t('Route')}}：{{order.lineName}}</div>
        <!--出發日期-->
        <div class="item">{{$t('DepartureDate')}}：{{order.priceDate}}</div>
        <!--訂單編號-->
        <div class="item">{{$t('Order number')}}：{{order.orderNo}}</div>
      </div>
      <div class="order-detail-group">
        <!--原訂單明細-->
        <div class="order-detail-item">
          <div class="hd cl">
            <!--計價貨幣-->
            <div class="fr">{{$t('TradingCurrency')}}：{{this.$t('Currencys')[order.billingType]}}</div>
            <!--原訂單明細-->
            <div class="b">{{$t('OriginalOrderDetails')}}</div>
          </div>
          <charge-details-table :order="order"/>
        </div>
        <!--退款明細-->
        <div class="order-detail-item">
          <div class="hd cl">
            <!--計價貨幣-->
            <div class="fr">{{$t('TradingCurrency')}}：{{this.$t('Currencys')[order.billingType]}}</div>
            <!--退款明細-->
            <div class="b">{{$t('RefundDetails')}}</div>
          </div>
          <div class="table border">
            <table>
              <thead>
              <tr>
                <th>{{$t('Project')}}</th>
                <th>{{$t('Price')}}</th>
                <th>{{$t('Quantity')}}</th>
                <th>{{$t('Amount')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item of cancelOrderDetailsFilter" :key="item.detailsId">
                <td>{{item.cancelCategoryName}}</td>
                <td>{{item.unitPrice}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.feeAmount}}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr class="b">
                <td>{{$t('Total')}}</td>
                <td class="txt-r" colspan="3">{{order.billingType}} {{cancelOrderTotal}}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <!--新訂單明細-->
        <div class="order-detail-item" v-if="newOrderDetails&&newOrderDetails.length">
          <div class="hd cl">
            <!--計價貨幣-->
            <div class="fr">{{$t('TradingCurrency')}}：{{this.$t('Currencys')[order.billingType]}}</div>
            <!--新訂單明細-->
            <div class="b">{{$t('NewOrderDetails')}}</div>
          </div>
          <div class="table border">
            <table>
              <thead>
              <tr>
                <th>{{$t('Project')}}</th>
                <th>{{$t('Price')}}</th>
                <th>{{$t('Quantity')}}</th>
                <th>{{$t('Amount')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item of newOrderDetailsFilter" :key="item.detailsId">
                <td>{{item.cancelCategoryName}}</td>
                <td>{{item.unitPrice}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.feeAmount}}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr class="b">
                <td>{{$t('Total')}}</td>
                <td class="txt-r" colspan="3">{{order.billingType}} {{newOrderTotal}}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="btns">
        <button class="button m-r" @click="setStep(0)">{{$t('PreviousStep')}}</button>
        <button class="button button-primary" @click="$refs.submitModal.show()">{{$t('NextStep')}}</button>
      </div>
    </div>
    <submit-modal ref="submitModal" :isDay="isDay"/>
  </div>
</template>
<script>
  import Checkbox from '../../../components/Checkbox';
  import CancellationPolicyTable from '../../../components/CancellationPolicyTable';
  import ChargeDetailsTable from '../components/ChargeDetailsTable';
  import SubmitModal from './components/SubmitModal';
  import RefundFeesForm from './components/RefundFeesForm';
  import order from '../../../mixins/order';
  import numberOptions from '../../../mixins/numberOptions';
  import {scrollToY} from '../../../utils';
  import {
    getCancelOrderInfo,
    cancelOrderBusiness,
    getCancelOrderDetails,
    getCancelOrderUpdateInfo, adminGetProduct
  } from '../../../api/admin-order';
  import {getDictList} from '../../../api/admin-common';
  import {getRefundPolicy} from '../../../api/admin-product';

  export default {
    name: 'CancelOrderApplication',
    mixins: [order, numberOptions],
    data() {
      return {
        step: 0,
        orderId: '',
        cancelReasonOptions: [],
        cancelReason: '',
        otherReasons: '',
        order: {},
        travelers: [],
        // 补偿项
        plusDetails: [],
        // 扣减项
        deductionDetails: [],
        // 新订单费用详情
        newDetails: [],
        // 拼床旅客的索引
        occupyBedIndex: 0,
        // 退款明细（第二步）
        cancelOrderDetails: [],
        // 新订单明细（第二步）
        newOrderDetails: [],
        refundPolicyContent: '',
        policies: [],
        isDay: 0
      };
    },
    computed: {
      // 选中的旅客
      selectedTravelers() {
        return this.travelers.filter(item => item.isSelected);
      },
      // 未选中的旅客
      uncheckedTravelers() {
        return this.travelers.filter(item => !item.isSelected);
      },
      // 退款詳情過濾
      cancelOrderDetailsFilter() {
        let cancelOrderDetails;
        cancelOrderDetails = this.cancelOrderDetails.filter(item => {
          return item.sort !== 99;
        });
        return cancelOrderDetails;
      },
      // 新訂單詳情過濾
      newOrderDetailsFilter() {
        let newOrderDetails;
        newOrderDetails = this.newOrderDetails.filter(item => {
          return item.sort !== 99;
        });
        return newOrderDetails;
      },
      // 退款明细总额
      cancelOrderTotal() {
        let total = 0;
        for (let item of this.cancelOrderDetailsFilter) {
          total += item.feeAmount * 1000;
        }
        return total / 1000;
      },
      // 新订单明细总额
      newOrderTotal() {
        let total = 0;
        for (let item of this.newOrderDetailsFilter) {
          total += item.feeAmount * 1000;
        }
        return total / 1000;
      }
    },
    components: {CancellationPolicyTable, RefundFeesForm, ChargeDetailsTable, SubmitModal, Checkbox},
    created() {
      this.orderId = this.$route.query.id;
      this.getOrderDetail();
      this.getDicts();
    },
    methods: {
      setStep(step) {
        this.step = step;
        this.$nextTick(() => {
          scrollToY();
        });
      },
      // 获取数据字典
      getDicts() {
        // 商户取消原因
        getDictList('cancelReasonB').then(res => {
          this.cancelReasonOptions = res.value || [];
        });
      },
      // 获取产品基本信息
      adminGetProduct() {
        adminGetProduct(this.order.productId).then(res => {
          this.isDay = res.value.isDay;
        });
      },
      // 获取订单详情
      getOrderDetail() {
        getCancelOrderInfo(this.orderId).then(res => {
          const data = res.value || {};
          this.order = this.formatOrder(Object.assign(data.order, data.orderDetails));
          this.travelers = data.travelers;
          this.uncheckAllTraveler(); // 添加 isSelected，防止无法选中、取消选中
          this.getCancelOrderUpdateInfo();
          this.getRefundPolicy(this.order.lineId);

          this.adminGetProduct();
        });
      },
      // 获取退费政策
      getRefundPolicy(lineId) {
        getRefundPolicy(this.$i18n.locale, lineId).then(res => {
          const value = res.value;
          if (value.current) {
            this.refundPolicyContent = value.current.content;
            this.policies = value.policies || [];
          }
        });
      },
      // 获取取消订单的修改信息(提交前的临时数据)
      getCancelOrderUpdateInfo() {
        getCancelOrderUpdateInfo(this.orderId).then(res => {
          let data = res.value;
          if (data) {
            let datas = JSON.parse(data);
            const travelersId = datas.refundTravelers.map(item => item.id);
            this.cancelReason = datas.cancelReason;
            this.otherReasons = datas.otherReasons;
            this.plusDetails = datas.refundCancelDetails.filter(item => item.cancelCategory === 'plus');
            this.deductionDetails = datas.refundCancelDetails.filter(item => item.cancelCategory === 'deduction');
            this.newDetails = datas.newCancelDetails;
            this.travelers = this.travelers.map(item => {
              item.isSelected = travelersId.includes(item.id);
              return item;
            });
            this.$nextTick(() => {
              this.occupyBedIndex = datas.occupyBedIndex || 0;
              this.roomsNumber = datas.roomsNumber;
            });
          }
          if (!this.plusDetails.length) {
            this.plusDetails.push(this.addRefundItem('cancelOrder', 'plus'));
          }
          if (!this.deductionDetails.length) {
            this.deductionDetails.push(this.addRefundItem('cancelOrder', 'deduction'));
          }
          if (!this.newDetails.length) {
            this.newDetails.push(this.addRefundItem('newOrder', 'deduction'));
          }
        });
      },
      // 取消选中旅客
      uncheckTraveler(travelerId) {
        for (let item of this.travelers) {
          if (item.id === travelerId) {
            item.isSelected = false;
          }
        }
      },
      // 取消选中所有旅客
      uncheckAllTraveler() {
        this.travelers = this.travelers.map(item => {
          let newItem = {...item};
          newItem.isSelected = false;
          return newItem;
        });
      },
      // 增加补偿或扣减费用项
      // cancelType 退款:cancelOrder  新订单:newOrder
      addRefundItem(cancelType, cancelCategory) {
        return {
          id: new Date().getTime(),
          cancelType,
          cancelCategory,
          cancelCategoryName: '',
          feeType: cancelCategory,
          feeAmount: '',
          unitPrice: undefined,
          amount: undefined
        };
      },
      // 商家发起退款(下一步)
      cancelOrderBusiness() {
        // 退款旅客
        const refundTravelers = this.selectedTravelers.map(item => ({id: item.id}));
        const newTravelers = this.uncheckedTravelers.map((item, index) => {
          return {
            id: item.id,
            isFight: this.roomsNumber % 1 === 0.5 && this.occupyBedIndex === index ? 'Y' : 'N'
          };
        });
        // 退费费用详情
        const refundCancelDetails = [...this.plusDetails, ...this.deductionDetails].filter(item => item.cancelCategoryName && item.unitPrice && item.amount).map(item => {
          item.feeAmount = (item.unitPrice || 0) * 1000 * (item.amount || 0) / 1000;
          return item;
        });
        // 新订单费用详情
        const newCancelDetails = this.newDetails.filter(item => item.cancelCategoryName && item.unitPrice && item.amount).map(item => {
          item.feeAmount = (item.unitPrice || 0) * 1000 * (item.amount || 0) / 1000;
          return item;
        });
        const data = {
          orderId: this.orderId,
          cancelReason: this.cancelReason,
          otherReasons: this.otherReasons,
          roomsNumber: this.roomsNumber,
          occupyBedIndex: this.occupyBedIndex,
          refundTravelers,
          newTravelers,
          refundCancelDetails,
          newCancelDetails
        };

        if (this.maxChildNumber < 0 || this.maxBabyNumber < 0) {
          this.$message.error(`${this.adultNumber}成人最多能與${2 * this.adultNumber}小童+${this.adultNumber}嬰兒同行`);
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        data.cancelJson = JSON.stringify(data);
        cancelOrderBusiness(data).then(res => {
          loading.close();
          this.setStep(1);
          this.getCancelOrderDetails();
        }).catch(() => {
          loading.close();
        });
      },
      // 获取取消订单的详情
      getCancelOrderDetails() {
        getCancelOrderDetails(this.orderId).then(res => {
          const data = res.value;
          this.cancelOrderDetails = data.cancelOrder;
          console.log(this.cancelOrderDetails);
          this.newOrderDetails = data.newOrder;
        });
      }
    },
    watch: {
      uncheckedTravelers(newValue, oldValue) {
        let adultNumber = 0;
        let childNumber = 0;
        let babyNumber = 0;

        for (let item of newValue) {
          switch (item.travelerType) {
            case 'adult':
              adultNumber += 1;
              break;
            case 'child':
              childNumber += 1;
              break;
            case 'baby':
              babyNumber += 1;
              break;
          }
        }
        this.occupyBedIndex = 0;
        this.adultNumber = adultNumber;
        this.childNumber = childNumber;
        this.babyNumber = babyNumber;
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .title{font-size:24px;font-weight:bold;}
    .actions{
      padding-bottom:20px;border-bottom:1px dashed #ff6f61;
      .button{
        min-width:80px;padding-left:8px;padding-right:8px;
        &.m-r{margin-right:8px;}
      }
    }
    .container{
      position:relative;margin-top:40px;overflow:hidden;
      &:after{position:absolute;top:0;right:50%;bottom:0;width:0;border-right:1px dashed #ff6f61;content:'';}
    }
    /deep/ .table{margin-top:0;padding-bottom:0;}
    /deep/ .el-textarea .el-textarea__inner{height:96px;padding:10px;}
  }
  .main-left,
  .main-right{
    float:left;width:50%;
    > .hd{
      line-height:60px;text-align:center;font-size:24px;font-weight:bold;background-color:#fbe4e1;background-image:linear-gradient(45deg, #fdf7ee 0%, #fbe4e1 100%);
      &.m-t{margin-top:40px;}
    }
  }
  .main-left{padding-right:23px;}
  .main-right{padding-left:23px;}
  .fl-group{
    .hd{
      position:relative;margin:20px 0;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .btn{
      display:inline-block;margin-top:3px;margin-left:8px;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;border:1px solid #1890ff;border-radius:4px;
      &.btn-gray{color:#999;border-color:#ccc;}
    }
    .currency{padding-top:10px;font-size:14px;}
    .select-label{display:inline-block;line-height:32px;vertical-align:top;font-size:14px;}
    .select{
      width:228px;vertical-align:top;
      &.w1{width:88px;}
    }
    .m-t{margin-top:16px;}
  }
  .order-info{
    padding:20px;background:#f4f4f4;border-radius:4px;
    .p{
      margin-bottom:16px;
      &:last-child{margin-bottom:0;}
    }
  }
  .goods-info{
    margin-top:40px;padding:12px 0 24px 24px;overflow:hidden;;background-color:#f4f4f4;
    .item{float:left;margin-right:40px;margin-top:12px;}
  }
  .order-detail-group{display:flex;}
  .order-detail-item{
    flex:1;margin-left:2%;margin-top:24px;overflow:hidden;border:1px solid #ffe7e3;border-radius:4px 4px 0 0;
    &:first-child{margin-left:0;}
    > .hd{line-height:60px;padding:0 8px;background-color:#fbe4e1;background-image:linear-gradient(45deg, #fdf7ee 0%, #fbe4e1 100%);}
  }
  .btns{margin-top:24px;text-align:center;}
</style>
<style lang="less">
  .popover-refund-policy-wrap{
    padding:0;
    .policy-box{max-height:500px;max-height:60vh;padding:20px;overflow-y:auto;}
  }
</style>
