<template>
  <table class="table-policy" v-if="policyList&&policyList.length">
    <thead>
    <tr>
      <th>{{$t('BreachTime')}}</th>
      <th>
        <div>{{$t('PassengerBreach')}}</div>
        <div class="proportion-txt">{{$t('PassengerFeePercentage')}}</div>
      </th>
      <th v-if="showTravelAgency&&travelAgency">
        <div>{{$t('TravelAgencyBreach')}}</div>
        <div class="proportion-txt">{{$t('TravelAgencyFeePercentage')}}</div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item of policyList" :key="item.policyId">
      <!--n天或以上取消-->
      <td v-if="item.position==='start'">{{item.minDay}}{{$t('MoreThanAFewDays')}}</td>
      <!--n天至n天前取消-->
      <td v-else-if="item.position==='middle'">{{item.maxDay}}{{$t('DayTo')}}{{item.minDay}}{{$t('DaysAgo')}}</td>
      <!--行程開始當天取消-->
      <td v-else>{{$t('TheDayOfTheTrip')}}</td>
      <td>{{item.travelScale}}%</td>
      <td v-if="showTravelAgency&&travelAgency">{{item.adminScale || 0}}%</td>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import {sortPolicy} from '../utils';

  export default {
    name: 'CancellationPolicyTable',
    props: {
      policy: Array,
      showTravelAgency: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      policyList() {
        if (this.policy && this.policy.length) {
          return sortPolicy(this.policy);
        } else {
          return [];
        }
      },
      travelAgency() {
        return !!(this.policy || []).find(item => item.isAdmin === 'Y');
      }
    }
  };
</script>
<style scoped lang="less">
  .table-policy{
    width:100%;margin-top:20px;text-align:left;
    th,
    td{padding:8px;border:1px solid #f4f4f4;}
    .proportion-txt{font-size:12px;font-weight:normal;}
  }
</style>
