export default {
  data() {
    return {
      // 成人
      adultNumber: 1,
      // 長者
      elderlyNumber: 0,
      // 儿童
      childNumber: 0,
      // 婴儿
      babyNumber: 0,
      // 房间
      roomsNumber: 0.5
    };
  },
  computed: {
    // 最小成人数
    minAdultNumber: function () {
      return Math.ceil((this.childNumber + this.babyNumber) / 3);
    },
    // 最大小童数
    maxChildNumber: function () {
      return this.adultNumber * 3 - this.babyNumber;
    },
    // 最大婴儿数
    maxBabyNumber: function () {
      return this.childNumber > this.adultNumber * 2 ? (this.adultNumber * 3 - this.childNumber) : this.adultNumber;
    },
    // 最小房间数
    minRoomsNumber: function () {
      let adultNumber = this.adultNumber; // 成人数
      let childNumber = this.childNumber; // 小童数
      let babyNumber = this.babyNumber; // 婴儿数
      let roomsNumber = 0;
      for (let i = 1; i <= this.adultNumber; i++) {
        if (!childNumber && !babyNumber) {
          roomsNumber += adultNumber / 2;
          break;
        } else {
          // 如果够一间房（1成人+1婴儿+2小孩或者1成人+3小孩）
          if ((childNumber >= 2 && babyNumber >= 1) || childNumber >= 3) {
            roomsNumber++;
            adultNumber--;
            if (babyNumber) {
              babyNumber--;
              childNumber -= childNumber >= 2 ? 2 : childNumber;
            } else {
              childNumber -= childNumber >= 3 ? 3 : childNumber;
            }
          } else {
            if (childNumber === 0) {
              roomsNumber += adultNumber / 2;
              break;
            } else {
              roomsNumber++;
              adultNumber -= adultNumber > 1 ? 2 : 1;
              babyNumber -= babyNumber >= 1 ? 1 : 0;
              if (childNumber === 1) {
                childNumber--;
              } else if (childNumber === 2) {
                childNumber -= 2;
              }
            }
          }
        }
      }
      return roomsNumber;
    },
    // 小童选项
    childOptions: function () {
      let min = 0;
      let options = [];
      while (min <= this.maxChildNumber) {
        options.push({label: min, value: min});
        min += 1;
      }
      return options;
    },
    // 婴儿选项
    babyOptions: function () {
      let min = 0;
      let options = [];
      while (min <= this.maxBabyNumber) {
        options.push({label: min, value: min});
        min += 1;
      }
      return options;
    },
    // 房间选项
    roomsOptions: function () {
      let min = this.minRoomsNumber;
      let options = [];
      while (min <= this.adultNumber) {
        options.push({label: min, value: min});
        min += 0.5;
      }
      return options;
    },
    // 房数详细分配（大人占床数、大人附加床数、小童占床数、小童不占床数）
    roomNumberDetail: function () {
      let adultNumber = this.adultNumber;
      let childNumber = this.childNumber;
      let roomsNumber = this.roomsNumber;
      let notChildRoomsNumber = 0; // 小童不占床房间数
      let childRoomsNumber = 0; // 小童占床房间数
      let adultRoomsNumber = adultNumber * 0.5; // 大人房间数
      let lastRoomsNumber = roomsNumber - adultRoomsNumber; // 除去大人房间数之后的数
      let annexNumber = 0; // 附加床数量
      if (lastRoomsNumber > 0) {
        if (childNumber > 0) {
          let temp = lastRoomsNumber / 0.5;
          // 附加床数量
          annexNumber = temp > childNumber ? (temp - childNumber) / 2 : 0;
          childRoomsNumber = lastRoomsNumber - annexNumber;
          notChildRoomsNumber = childNumber - (childRoomsNumber * 2);
        } else {
          // 附加床数量
          annexNumber = lastRoomsNumber;
        }
      } else {
        notChildRoomsNumber += childNumber - (childRoomsNumber + notChildRoomsNumber);
      }
      return {
        adultRoomsNumber: adultRoomsNumber * 2,
        annexNumber: annexNumber * 2,
        childRoomsNumber: childRoomsNumber * 2,
        notChildRoomsNumber
      };
    },
    // 保险价格
    insurancePrice: function () {
      let prices = {adult: 0, child: 0, sum: 0};
      const insurance = this.currentPrice.insurance;
      const days = (this.currentPrice.days || this.currentPrice.lates);
      if (this.isInsurance === 'Y' && insurance) {
        const adult = insurance.adultPrice * 100 * days * this.adultNumber;
        prices.adult = adult;
        // 如果不是统一价格，还要计算小孩的保险费用
        if (this.currentPrice.priceType !== '1') {
          prices.child = insurance.childPrice * 100 * days * (this.childNumber + this.babyNumber);
        }
        prices.sum = prices.adult + prices.child;
      }
      return prices;
    },
    // 总额
    totalPrice: function () {
      if (this.currentPrice.priceId) {
        const {priceType, adultPrice, elderlyPrice, childPrice, notChildPrice, babyPrice, singleBedPrice} = this.currentPrice;
        const {adultRoomsNumber, annexNumber, childRoomsNumber, notChildRoomsNumber} = this.roomNumberDetail;

        // 统一价格
        if (priceType === '1') {
          return (adultPrice * adultRoomsNumber + singleBedPrice * annexNumber + this.insurancePrice.sum) / 100;
        } else {
          return (adultPrice * adultRoomsNumber + elderlyPrice * this.elderlyNumber + childPrice * childRoomsNumber + notChildPrice * notChildRoomsNumber + babyPrice * this.babyNumber + singleBedPrice * annexNumber + this.insurancePrice.sum) / 100;
        }
      } else {
        // adultPrice = ((this.prices[0] || {}).productPrice || 0) * 100 * this.adultNumber;
        return '--';
      }
    }
  },
  watch: {
    adultNumber: function (value) {
      this.roomsNumber = this.minRoomsNumber;
    },
    childNumber: function (value) {
      this.roomsNumber = this.minRoomsNumber;
    },
    babyNumber: function (value) {
      this.roomsNumber = this.minRoomsNumber;
    }
  }
};
